import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.footer`
  margin: 6rem 0 1rem 0;
  padding: 1rem ${props => props.theme.spacer.horizontal};
  text-align: center;
  color: ${props => props.theme.colors.grey};
  a {
    text-decoration: none;
    color: ${props => props.theme.brand.primary};
  }
`

const Footer = () => (
  <Wrapper data-testid="footer">
    For inquiries, <a href="mailto:beautybycaseylauren@gmail.com">Email</a> or find <b>BEAUTY by Casey Lauren</b> on{' '}
    <a href="https://www.instagram.com/beautybycaseylauren/">Instagram</a>. Crafted {' by '}{' '}
    <a href="https://sansfaux.com">Sans Faux Studios</a>.
  </Wrapper>
)

export default Footer
