module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: 'BEAUTY by Casey Lauren', // Navigation and Site Title
  siteTitleAlt: 'Beauty, by Casey Lauren', // Alternative Site title for SEO
  siteHeadline: 'Bringing a unique touch to your special day.', // Headline for schema.org JSONLD
  siteTitleShort: 'BEAUTY by Casey', // short_name for manifest
  siteUrl: 'https://beautybycaseylauren.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Beauty, by Casey Lauren',
  author: 'Sans Faux Studios', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@', // Twitter Username
  // ogSiteName: '', // Facebook Site Name
  ogLanguage: 'en_US',
  googleAnalyticsID: 'UA-138210532-3',

  // Manifest and Progress color
  themeColor: '#dcb239',
  backgroundColor: '#fedcd2',
}
